<script lang="ts" setup></script>

<template>
  <div>
    <div class="text-center text-lg md:text-2xl">ขออภัย! การเข้าถึงถูกปฏิเสธ (403 Forbidden)</div>
    <div class="text-center text-sm md:text-lg text-grey-4">เราไม่สามารถให้คุณเข้าถึงทรัพยากรที่คุณขอได้<br/>เนื่องจากคุณไม่ได้รับอนุญาตให้เข้าถึง</div>
    <v-alert
      type="info"
      color="grey-4"
      text="หากคุณเชื่อว่ามีความผิดพลาด หรือมีคำถามเกี่ยวกับการเข้าถึงทรัพยากร กรุณาติดต่อทีมงานเรา"
      variant="outlined"
      class="mt-2 text-sm"
    ></v-alert>
  </div>
</template>

<style scoped></style>
